@import './shared.scss';

.p-default {
  @include p-default;
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}

.p-vertical {
  @include p-vertical;
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}

.p-horizontal {
  @include p-horizontal;
}

.p-top {
  @include p-top;
}

.p-right {
  @include p-right;
}

.p-bottom {
  @include p-bottom;
}

.p-left {
  @include p-left;
}
