* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    @include media-breakpoint-down(sm) {
        font-size: 0.9375rem;
    }
}

h1,
.h1 {
    line-height: 3.5rem;
    margin-bottom: 1rem;
}

h2,
.h2 {
    line-height: 2.25rem;
    // margin-bottom: 1rem;
}

h3,
.h3 {
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
}

h4,
.h4 {
    line-height: 1.5rem;
    // margin-bottom: 1rem;
    letter-spacing: 4%;
}

p {
    // font-size: 1.125rem;
    // line-height: 1.5rem;
    font-weight: 300;
    // margin-bottom: 1.25rem;
}

.small {
    text-transform: uppercase;
}

blockquote,
.blockquote {
    font-style: italic;
    font-size: 1.125rem;
    // line-height: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
}

.small {
    // font-size: 0.8125rem;
    line-height: 1.25rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
}
