@import './../../styles/shared';

.slide01 {
    min-width: 100%;
    max-width: 100%;
    min-height: 60vh;
    display: block;
}

.slider01 {
    display: block;
    padding: 0 0;

    &.disable-transition .slider01-panel {
        transition: none;
    }

    /**
    * “After a tap, also a click is being triggered, I don’t want that!”
    * https://hammerjs.github.io/tips/
    * https://gist.github.com/jtangelder/361052976f044200ea17
    */
    &.disable-transition * {
        pointer-events: none;
    }

    img {
        pointer-events: none;
    }
}

.slider01-view {
    position: relative;
    overflow: hidden;
}

.slider01-panel {
    display: flex;
    flex-wrap: nowrap;
    transform: translateX(0);
    transition: transform 400ms ease;
    will-change: transform;
}

.slider01-actions {
    position: absolute;
    z-index: 10;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    // 800px
    @include media-breakpoint-up(md) {
        padding: 30px 0;
    }
}

.slider01-action-prev,
.slider01-action-next {
    width: 1rem;
    height: 1rem;
    margin: 0 15px;
    padding: 0.1rem;

    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    opacity: 0.5;

    transition: opacity 300ms ease, color 300ms ease, border-color 300ms ease;
    // background-color: white;
    color: #fff;

    user-select: none;
    cursor: pointer;

    // Chevron
    &:before {
        border-style: solid;
        border-width: 0.25em 0.25em 0 0;
        content: '';
        display: inline-block;
        height: 0.8rem;
        width: 0.8rem;
    }

    &:hover {
        text-decoration: none;
        opacity: 1;
        color: #fff;
    }

    &.is-disabled {
        cursor: default;
        opacity: 0.1;
        pointer-events: none;
    }
}

.slider01-action-prev::before {
    transform: rotate(-135deg);
}

.slider01-action-next::before {
    transform: rotate(45deg);
}

.slider01-indicator-container {
    margin: 0 -10px;
    display: flex;
}

.slider01-indicator {
    display: block;
    margin: 0 10px;
    background-color: white;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    opacity: 0.5;
    transition: opacity 300ms ease, color 300ms ease, border-color 300ms ease;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    &.is-active {
        cursor: default;
        opacity: 1;
        pointer-events: none;
    }
}
