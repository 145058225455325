
.theme-default {
    background-color: #ffffff !important;
}

.theme-grey {
    background-color: #EDEEF2 !important;
}

.theme-inverted {
    background-color: #333333 !important;
    color: white !important;
}

.theme-bordered {
    box-shadow: inset 0px -1px 0px #D9DADE;
}
