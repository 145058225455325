@charset "UTF-8";
.slider02 {
  display: block;
  padding: 0 0;
  /**
    * “After a tap, also a click is being triggered, I don’t want that!”
    * https://hammerjs.github.io/tips/
    * https://gist.github.com/jtangelder/361052976f044200ea17
    */ }
  .slider02.disable-transition .slider02-panel {
    transition: none; }
  .slider02.disable-transition * {
    pointer-events: none; }
  .slider02 img {
    pointer-events: none; }

.slider02-view {
  position: relative;
  overflow: hidden; }

.slider02-view-width {
  width: 100%;
  position: absolute; }

.slider02-panel {
  display: flex;
  flex-wrap: nowrap;
  transform: translateX(0);
  transition: transform 400ms ease;
  will-change: transform; }

.slider02-actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0; }
  @media (min-width: 800px) {
    .slider02-actions {
      padding: 40px 0; } }
  @media (min-width: 2400px) {
    .slider02-actions {
      padding: 50px 0; } }

.slider02-action-prev,
.slider02-action-next {
  width: 1rem;
  height: 1rem;
  margin: 0 15px;
  padding: 0.1rem;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  transition: opacity 300ms ease;
  color: #333333;
  user-select: none;
  cursor: pointer; }
  .slider02-action-prev:before,
  .slider02-action-next:before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 0.8rem;
    width: 0.8rem; }
  .slider02-action-prev:hover,
  .slider02-action-next:hover {
    text-decoration: none;
    opacity: 1;
    color: #333333; }
  .slider02-action-prev.is-disabled,
  .slider02-action-next.is-disabled {
    cursor: default;
    opacity: 0.1;
    pointer-events: none; }

.slider02-action-prev::before {
  transform: rotate(-135deg); }

.slider02-action-next::before {
  transform: rotate(45deg); }
