@import './../../styles/shared.scss';

.banner {
    position: relative;
    width: 100%;
    margin: 0;
}

.banner-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    img {
        width: 100%;
        height: 100%;
    }
}

.banner-overlay {
    position: relative;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.8);
    @include media-breakpoint-up(lg) {
        min-height: 420px;
        display: flex;
        align-items: center;
    }
}

.banner-content {
    color: white;
}
