@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@mixin p-default {
  @include p-vertical();
  @include p-horizontal();
}

@mixin p-horizontal {
  @include p-left();
  @include p-right();
}

@mixin p-vertical {
  @include p-top();
  @include p-bottom();
}

@mixin p-bottom {
 padding-bottom: 60px;

 // 800px
  @include media-breakpoint-up(md) { padding-bottom: 60px; }

 // 1000
  @include media-breakpoint-up(lg) { padding-bottom: 5vw; }

 // 1600px
  @include media-breakpoint-up(xxl) { padding-bottom: 80px; }
}

@mixin p-top {
 padding-top: 60px;

 // 800px
  @include media-breakpoint-up(md) { padding-top: 60px; }

 // 1000
  @include media-breakpoint-up(lg) { padding-top: 5vw; }

 // 1600px
  @include media-breakpoint-up(xxl) { padding-top: 80px; }
}


@mixin p-left {
 padding-left: 20px;
 // 800px
  @include media-breakpoint-up(md) { padding-left: 5vw; }

 // 1000
  @include media-breakpoint-up(lg) { padding-left: 5vw; }

 // 1600px
  @include media-breakpoint-up(xxl) { padding-left: 80px; }
}

@mixin p-right {
 padding-right: 20px;

 // 800px
  @include media-breakpoint-up(md) { padding-right: 5vw; }

 // 1000
  @include media-breakpoint-up(lg) { padding-right: 5vw; }

 // 1600px
  @include media-breakpoint-up(xxl) { padding-right: 80px; }
}
