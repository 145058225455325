@import './../../styles/shared';

.text-image {
    @include media-breakpoint-up(md) {
        display: flex;
    }

    @include media-breakpoint-down(sm) {
        .text {
            &:before {
                display: none !important;
            }
        }
    }

    .text {
        background-color: white;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:before {
            display: block;
            content: ' ';
            width: 1rem;
            height: 1rem;
            background-color: inherit;
            position: absolute;
            transform: rotate(45deg);
            margin: -0.5rem;
            left: 50%;
            top: 0;
        }
        // IE cannot inherit background color from parent
        // You have do add the actual theme colors
        // &:before {
        //     background-color: white;
        // }
        // &.theme-default::before {
        //     background-color: #ffffff;
        // }
        // &.theme-grey::before {
        //     background-color: #edeef2;
        // }
        // &.theme-inverted::before {
        //     background-color: #333333;
        // }
    }

    .image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        min-height: (100/16) * 9vw;
    }

    @include media-breakpoint-up(md) {
        &.image-left .text,
        &.image-right .image {
            order: 2;
        }

        &.image-left .image,
        &.image-right .text {
            order: 1;
        }

        &.layout-50-50 {
            .text {
                width: 50%;
            }

            .image {
                width: 50%;
                min-height: (50/16) * 9vw;
            }
        }

        &.layout-35-65 {
            .text {
                width: 65%;
            }

            .image {
                width: 35%;
                min-height: (35/16) * 9vw;
            }
        }

        &.layout-35-65.image-left .text:before,
        &.layout-50-50.image-left .text:before {
            top: 50%;
            left: 0;
        }

        &.layout-35-65.image-right .text:before,
        &.layout-50-50.image-right .text:before {
            top: 50%;
            right: 0;
            left: auto;
        }
    }

    @include media-breakpoint-up(xxl) {
        &.layout-50-50 .image {
            min-height: ((map-get($container-max-widths, xxl) / 100 * 50)/16) * 9;
        }

        &.layout-35-65 .image {
            min-height: ((map-get($container-max-widths, xxl) / 100 * 35)/16) * 9;
        }
    }
}
