.main {
    background-color: white;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 2400px;
    margin: 0 auto;

}

.main-header {
    background-color: white;
    border-bottom: 1px solid #d9dade;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    padding: 0;
    position: relative;
    position: sticky;
    z-index: 50;
    top: 0;
}

.main-header {
    position: fixed !important;
    width: 100%;
    left: 0;
}
.main-content {
    flex-grow: 1;
    /*.has-overlay & {
        display: none;
    }*/
}

.main-footer {
    background-color: #444;
    .has-overlay & {
        display: none;
    }
}

section {
    .has-overlay & {
        display: none;
    }
}
.Grid.Top{
    .has-overlay & {
        display: inline;
    }
}
