@import './../../styles//shared';

.header-title {
    padding: 0.6rem 2rem;
    height: 72px;
    margin: 0;
    padding-left:0px;
}
// .header-logo
//     .header-logo-image

.nav {
    flex-grow: 1;
    display: none;
    /*height: 100%;*/
    width: 100%;

    &.is-active {
        display: flex;
    }

    @include media-breakpoint-up(md) {
        padding-left: 2rem;
        justify-content: flex-end;
        display: flex;
        width: auto;
    }
}

.nav-list-level-0,
.nav-list-level-1 {
    margin: 0;
    padding: 0;
}

.nav-list-level-0 {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        flex-grow: 0;
    }
}

.nav-list-level-1 {
    display: none;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-top: 0;
    // padding:  1rem 0;
    .nav-action {
        // height: auto;;
        // min-height: 0;
        // margin-top: 0.5rem;
        // margin-bottom: 0.5rem;
    }
    &.is-active {
        display: block;
    }

    @include media-breakpoint-up(md) {
        position: absolute;
        width: 100vw;
        max-width: 2400px;
        right: 0;
        z-index: 50;
    }
}

.nav-list-item {
    display: block;
    list-style-type: none;

    @include media-breakpoint-up(md) {
        &:hover {
            .nav-list-level-1 {
                border-top: 1px solid rgba(0, 0, 0, 0.15);
                background-color: white;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                // flex-direction: column;
            }
        }
    }
}

.nav-action {
    display: block;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 71px; // Fixes IE BUG - https://github.com/philipwalton/flexbugs/issues/231
    min-height: 72px;
    display: flex;
    align-items: center;
    &:hover {
        background-color: rgba(0, 0, 0, 0.15);
        text-decoration: none;
    }
}

.nav-toggle-wrapper {
    /*height: 72px;*/
    padding: 0 2rem;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    /*height: 100%;*/
    font-size: 1.2em;
    @include media-breakpoint-up(md) {
        display: none !important;
    }
}

// CSS Burger
$lineWidth: 0.15em;

.nav-toggle {
    width: 1em;
    height: 1em;
    position: relative;;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

.nav-toggle span {
    display: block;
    position: absolute;
    height: $lineWidth;
    width: 100%;
    background: #000;
    transform-origin: center center;
    transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.nav-toggle span:nth-child(2),
.nav-toggle span:nth-child(3) {
    transform: translateY(0.5em - $lineWidth/2);
}

.nav-toggle span:nth-child(4) {
    transform: translateY(1em-$lineWidth);
}

.nav-toggle.is-active span:nth-child(1),
.nav-toggle.is-active span:nth-child(4) {
    transform: translateY(0.5em - $lineWidth/2) scale(0.01);
}

.nav-toggle.is-active span:nth-child(2) {
    transform: translateY(0.5em - $lineWidth/2) rotate(45deg);
}

.nav-toggle.is-active span:nth-child(3) {
    transform: translateY(0.5em - $lineWidth/2) rotate(-45deg);
}
